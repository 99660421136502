import { createVuetify } from 'vuetify'
import { aliases, md } from 'vuetify/iconsets/md'
import { fa } from 'vuetify/iconsets/fa'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { createVueI18nAdapter } from 'vuetify/locale/adapters/vue-i18n'
import { useEnvVar } from '@kidzonet/vue3/use/envVar'

import 'vuetify/styles'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

import i18n from '@kidzonet/dashboard-i18n'
import { useI18n } from 'vue-i18n'

import { getVuetifyTheme } from '@kidzonet/vue3/app-config/vuetifyTheme'

import {
    kidzonetDashboardAliases,
    kidzonetDashboardIconSet,
} from '@kidzonet/dashboard-icons'

const theme = getVuetifyTheme()

if (useEnvVar('VITE_SAAS') === 'orange-cameroon') {
    theme.themes.light.colors.primary = '#ff7900'
    theme.themes.light.colors.secondary = '#FED02F'
    theme.themes.light.colors.tableFooter = '#ffc999'
}

export default createVuetify({
    components: {
        ...components,
    },
    directives,
    theme,
    icons: {
        aliases: {
            ...aliases,
            ...kidzonetDashboardAliases,
        },
        defaultSet: 'kidzonetDashboardIconSet',
        sets: {
            kidzonetDashboardIconSet,
            md,
            fa,
        },
    },
    locale: {
        // @ts-expect-error vuetify types
        adapter: createVueI18nAdapter({ i18n, useI18n }),
    },
})
