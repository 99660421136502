import { createApp } from 'vue'
import Notifications from '@kyvg/vue3-notification'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

import authApi from '@kidzonet/dashboard-api-auth'

import { createPinia } from '@kidzonet/pinia'
import SentryInit from '@kidzonet/vue3/sentry'

import '@kidzonet/chunk-loading-error-listener'

import i18n from '@kidzonet/dashboard-i18n'
import router from './router'
import App from './components/App/App.vue'
import VuetifyPlugin from './vuetify'

declare global {
    interface Window {
        AUTH_API: any
        SESSIONS_API: any
        DELETE_SESSIONS_API: any
        KIDZONET_VUE_I18N: any
        safari: any
        EXCLUSION_LIST_API: any
        NETS_STORE: any
    }
}

window.AUTH_API = authApi

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate as any)

const app = createApp(App)

app.use(pinia as any)
app.use(Notifications)

app.use(VuetifyPlugin)

app.use(router)
app.use(i18n)

app.mount('#app')

SentryInit(app, 'dashboard', router)
