import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'

export default (app: object, tag: string, router?: object) => {
    if (process.env.NODE_ENV !== 'production') {
        return
    }

    if (localStorage.getItem('nosentry')) {
        return
    }

    if (window.location.search.substr(1) === 'nosentry') {
        localStorage.setItem('nosentry', 'true')
        window.history.replaceState(null, '', window.location.pathname)
        return
    }
    const integrations = []

    if (router) {
        integrations.push(new BrowserTracing({
            // @ts-expect-error internal sentry 'VueRouter' type
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }))
    }

    Sentry.init({
        // @ts-expect-error internal sentry "Vue' type
        app,
        dsn: 'https://a21682872eca4e9d9b4d5e7eff78d14f@o456033.ingest.sentry.io/5448696',
        integrations,
        beforeSend (event, hint) {
            console.log('event', event)
            console.log('hint', hint)
            // @ts-expect-error internal sentry "Vue' type
            const error = typeof hint.originalException === 'string' ? hint.originalException : hint?.originalException?.message
            if (error && error.includes('createRouterError')) {
                return null
            }
            return event
        },
    })

    Sentry.setTag('app', tag)
}
