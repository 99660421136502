import { ApiClient, AuthApi } from '@kidzonet/openapi-auth-v1'

import BaseAPIClass from '@kidzonet/api-common/src/AuthApi'

export default class extends BaseAPIClass {
    afterInitilize () {
        this.restAuthApiClient = ApiClient
        this.restAuthServiceApi = AuthApi
        this.setRestApi()
    }

    refreshApiMethod (params) {
        return this.restAuthClient.refresh(params)
    }

    revokeApiMethod (params) {
        return this.restAuthClient.revoke(params)
    }

    async _getAuthInfo () {
        const result = await this.restAuthClient.getAuthInfo()
        const { user, admin } = { ...result }
        return { user: { ...user }, admin: { ...admin }, sessionId: result.session_id }
    }

    getShortRefreshToken (commit) {
        return this.checkTokenAndCallMethod(
            this._getShortRefreshToken, [], commit,
        )
    }

    async _getShortRefreshToken () {
        const r = await this.restAuthClient.getShortRefreshToken()
        return r.token
    }

    async authenticate ({ username, password }) {
        try {
            const result = await this.restAuthClient.login({ username, password })
            await this.saveRefreshedToken({
                accessToken: result.access_token,
                refreshToken: result.refresh_token,
                expiresIn: new Date().getTime() / 1000 + result.expires_in,
            })
            return true
        } catch (e) {
            if (!e.body) {
                throw e
            }
            if (e.body.status === 401 || e.body?.detail[0]?.type === 'value_error.any_str.min_length') {
                return e.body
            }
            console.error(e)
            return e.body
        }
    }

    async signUpUser (params) {
        try {
            await this.restAuthClient.signUpCustomer(params)
            return true
        } catch (e) {
            console.error(e)
            return e.body
        }
    }
}
