export default function getRestApiUrl () {
    let apiUrl = ''
    try {
        apiUrl = process.env.VUE_APP_REST_API_URL || ''
        if (process.env.NODE_ENV === 'production') {
            apiUrl = `${window.location.protocol}//rest.${window.location.host}`
        }
    } catch (e) {
        apiUrl = `${window.location.protocol}//rest.${window.location.host}`
    }

    return localStorage.getItem('VUE_APP_REST_API_URL-override')
      || localStorage.getItem('VUE_APP_REST_API_URL') || apiUrl
}
