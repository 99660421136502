import getRestApiUrl from './getRestApiUrl'
import { ErrorInterface } from './types'

interface tokenDetails {
    accessToken: string
    refreshToken: string
    expiresIn: string
}

// @ts-expect-error api will be rewritten
export default superclass => class extends superclass {
    /* eslint
    class-methods-use-this: ["error", { "exceptMethods": [
        'getAccessToken', 'getRefreshToken', 'getExpiresIn', 'saveRefreshedToken',
        'getRefreshTokenPendingStatus', 'startRefreshTokenUpdate', 'mindcontrol',
        'clearStorage', clearPending
    ] }]
    */

    constructor (errorHandler: (e: ErrorInterface) => void) {
        super(errorHandler)
    }

    clearPending () {
        localStorage.setItem('kidzonet-refresh-token-pending', 'false')
        localStorage.setItem('kidzonet-child-refresh-token-pending', 'false')
    }

    afterInitilize () {
        this.restApiUrl = getRestApiUrl()
        super.afterInitilize()
    }

    getAccessToken () {
        return localStorage.getItem('kidzonet-child-token')
          || localStorage.getItem('kidzonet-token')
    }

    getRefreshToken () {
        return localStorage.getItem('kidzonet-child-refresh-token')
          || localStorage.getItem('kidzonet-refresh-token')
    }

    getExpiresIn () {
        return localStorage.getItem('kidzonet-child-token-expires-in')
          || localStorage.getItem('kidzonet-token-expires-in')
    }

    getRefreshTokenPendingStatus () {
        return localStorage.getItem('kidzonet-child-refresh-token-pending') === 'true'
          || localStorage.getItem('kidzonet-refresh-token-pending') === 'true'
    }

    startRefreshTokenUpdate () {
        if (localStorage.getItem('kidzonet-child-refresh-token')) {
            localStorage.setItem('kidzonet-child-refresh-token-pending', 'true')
        } else {
            localStorage.setItem('kidzonet-refresh-token-pending', 'true')
        }
    }

    saveRefreshedToken ({ accessToken, refreshToken, expiresIn }: tokenDetails) {
        if (localStorage.getItem('kidzonet-child-refresh-token')) {
            this.mindcontrol({ accessToken, refreshToken, expiresIn })
        } else {
            localStorage.setItem('kidzonet-token', accessToken)
            localStorage.setItem('kidzonet-token-expires-in', expiresIn)
            localStorage.setItem('kidzonet-refresh-token', refreshToken)
            localStorage.setItem('kidzonet-refresh-token-pending', 'false')
        }
    }

    mindcontrol ({ accessToken, refreshToken, expiresIn }: tokenDetails) {
        localStorage.setItem('kidzonet-child-token', accessToken)
        localStorage.setItem('kidzonet-child-token-expires-in', expiresIn)
        localStorage.setItem('kidzonet-child-refresh-token', refreshToken)
        localStorage.setItem('kidzonet-child-refresh-token-pending', 'false')
    }
}
