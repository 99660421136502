import AbstractAPI from './AbstractAPI'

export default class extends AbstractAPI {
    /* eslint
        class-methods-use-this: ["error", {
            "exceptMethods": ["getUserFromProto", "getSessionFromResponse"]
        }]
    */

    getUserFromProto ({
        id, email, username, role, avatar,
    }) {
        return {
            id: Number(id),
            email,
            username,
            role,
            avatar,
        }
    }

    getAuthInfo (commit) {
        return this.checkTokenAndCallMethod(
            this._getAuthInfo, [], commit,
        )
    }

    updateUser (args, commit) {
        return this.checkTokenAndCallMethod(
            this._updateUser, [args], commit,
        )
    }

    getUserSessions (args, commit) {
        return this.checkTokenAndCallMethod(
            this._getUserSessions, [args], commit,
        )
    }

    deleteSession (args, commit) {
        return this.checkTokenAndCallMethod(
            this._deleteSession, [args], commit,
        )
    }
}
