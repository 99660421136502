import { useTheme } from '../use/theme'

export const getVuetifyTheme = (primary = '#62269E') => ({
    defaultTheme: useTheme(),
    themes: {
        light: {
            dark: false,
            colors: {
                primary,
                defaultPrimary: '#62269E',
                secondary: '#843BCD',
                accent: '#82B1FF',
                error: '#FF5252',
                info: '#561fa1',
                success: '#4CAF50',
                warning: '#FFC107',
                background: '#fafafa',
                switcher: primary,
                mainMenu: primary,
                tabs: primary,
                floatingActionButton: primary,
                primaryButton: primary,
                purpleButton: primary,
                mainMenuText: '#fff',
                mainMenuActiveText: '#fff',
                mainContentBackground: '#F8FAFB',
                subHeader: '#888C9A',
                tableFooter: '#EEEDFC',
                oppositePrimary: '#fff',
                mainMenuActive: '#fff',
                mainMenuIcon: '#fff',
                tableAccent: '#000',
            },
        },
        dark: {
            dark: true,
            colors: {
                mainMenu: '#424242',
                mainMenuText: '#424242',
                mainMenuActiveText: '#424242',
                primary: '#fff',
                secondary: '#424242',
                floatingActionButton: '#424242',
                primaryButton: '#424242',
                purpleButton: primary,
                mainContentBackground: '#121212',
                tableFooter: '#424242',
                oppositePrimary: primary,
                mainMenuActive: '#fff',
                mainMenuIcon: '#fff',
                tableAccent: '#fff',
                defaultPrimary: '#fff',
            },
        },
    },
})

export default getVuetifyTheme()
