import { h } from 'vue'
import { md } from 'vuetify/iconsets/md'
import type { IconAliases, IconProps, IconSet } from 'vuetify'

import './kidzonet-icons/style.css'

interface KidzonetIconAliases extends IconAliases {
    mainMenuOverview: string
    mainMenuPolicy: string
    mainMenuCustomPolicy: string
    mainMenuNets: string
    mainMenuReports: string
    mainMenuMobileApp: string
    passwordFieldEye: string
    passwordFieldEyeSlash: string
    OverviewCurrentPolicyIcon: string
    OverviewCustomPolicyIcon: string
    PolicyIconHolidayTime: string
    PolicyIconKidsHomeAlone: string
    PolicyIconStudyTime: string
    PolicyIconBlockYoutube: string
    PolicyIconBlockSocialMedia: string
    PolicyIconBlockOnlineGames: string
    PolicyIconBlockOnlineMusicAndVideo: string
    PolicyIconSafeYouTube: string
    PolicyIconSafeSearch: string
    PolicyIconAllowAll: string
    PolicyIconBlockAll: string
    Trash: string
    Schedule: string
    TimePickerArrowUp: string
    TimePickerArrowDown: string
    MainMenuUser: string
    MainMenuActiveSessions: string
    MainMenuHelp: string
    MainMenuPrivacy: string
    MainMenuTOS: string
    MainMenuLogout: string
    MobileRefreshQRCode: string
    OneClickProtectionOn: string
}

// @ts-expect-error no default icons
const kidzonetDashboardAliases: KidzonetIconAliases = {
    mainMenuOverview: 'kidzonet-icons-menu-overview',
    mainMenuPolicy: 'kidzonet-icons-protection-policy',
    mainMenuCustomPolicy: 'kidzonet-icons-custom-policy',
    mainMenuNets: 'kidzonet-icons-nets',
    mainMenuReports: 'kidzonet-icons-reports',
    mainMenuMobileApp: 'kidzonet-icons-mobile-app',
    Schedule: 'kidzonet-icons-timer',
    passwordFieldEye: 'kidzonet-icons-eye',
    passwordFieldEyeSlash: 'kidzonet-icons-eye-slash',
    OverviewCurrentPolicyIcon: 'kidzonet-icons-current-policy-bold',
    OverviewCustomPolicyIcon: 'kidzonet-icons-custom-policy-bold',
    PolicyIconHolidayTime: 'kidzonet-icons-holiday-time',
    PolicyIconKidsHomeAlone: 'kidzonet-icons-kids-home-alone',
    PolicyIconStudyTime: 'kidzonet-icons-study-time',
    PolicyIconBlockYoutube: 'kidzonet-icons-block-youtube',
    PolicyIconBlockSocialMedia: 'kidzonet-icons-block-social-media',
    PolicyIconBlockOnlineGames: 'kidzonet-icons-block-online-games',
    PolicyIconBlockOnlineMusicAndVideo: 'kidzonet-icons-block-online-music-and-video',
    PolicyIconSafeYouTube: 'kidzonet-icons-restricted-youtube',
    PolicyIconSafeSearch: 'kidzonet-icons-safe-search',
    PolicyIconAllowAll: 'kidzonet-icons-allow-all',
    PolicyIconBlockAll: 'kidzonet-icons-block-all',
    Trash: 'kidzonet-icons-trash',
    TimePickerArrowUp: 'kidzonet-icons-arrow-up',
    TimePickerArrowDown: 'kidzonet-icons-arrow-down',
    MainMenuUser: 'kidzonet-icons-user',
    MainMenuActiveSessions: 'kidzonet-icons-security-time',
    MainMenuHelp: 'kidzonet-icons-message-question',
    MainMenuPrivacy: 'kidzonet-icons-protection-policy',
    MainMenuTOS: 'kidzonet-icons-document-text',
    MainMenuLogout: 'kidzonet-icons-logout',
    MobileRefreshQRCode: 'kidzonet-icons-rotate-right',
    OneClickProtectionOn: 'kidzonet-icons-protection-on',
}

const kidzonetDashboardIconSet: IconSet = {
    component: (props: IconProps) => {
        let iconName: string = props.icon || ''
        if (Object.prototype.hasOwnProperty.call(kidzonetDashboardAliases, iconName)) {
            iconName = kidzonetDashboardAliases[iconName]
            // @ts-expect-error vuetify types
            return h('i', { class: `${iconName} ${props?.class}` })
        }
        // eslint-disable-next-line ts/prefer-ts-expect-error
        // @ts-ignore strange error
        return md.component(props)
    },
}

export { kidzonetDashboardAliases, kidzonetDashboardIconSet }
