<script setup lang="ts">
import { computed, provide, ref } from 'vue'
import mitt from 'mitt'
import { useTheme } from 'vuetify'

import { useIsRtl, useLoadLocaleMessages } from '@kidzonet/vue3/i18n'
import { $busEmit, $busOff, $busOn } from '@kidzonet/vue3/injection/busEmit'

const theme = useTheme()

const loaded = ref(false)

await Promise.all([
    useLoadLocaleMessages('common'),
    useLoadLocaleMessages('web_common'),
    fetch('/config.json').then(res => res.json()),
]).then(([_a, _b, config]) => {
    Object.entries(config).forEach(([key, value]) => {
        localStorage.setItem(key, String(value))
    })
    loaded.value = true
})

document.body.classList.add(theme.global.name.value)

const emitter = mitt()

provide($busOn, (name: string, callback?: () => void) => {
    // @ts-expect-error strange mitt type validation
    emitter.on(name, callback)
})
provide($busOff, (name: string, callback?: () => void) => {
    emitter.off(name, callback)
})
provide($busEmit, (name: string, callback?: () => void) => {
    emitter.emit(name, callback)
})

const isRtl = computed(() => useIsRtl())
</script>

<template>
    <div
        v-if="loaded"
        :class="{
            'kidzonet-app': true,
            [theme.global.name.value]: theme.global.name.value,
            'kidzonet-app--rtl': isRtl,
            'kidzonet-app--ltr': !isRtl,
        }"
    >
        <router-view />
        <notifications />
    </div>
    <div v-else>
        loading...
    </div>
</template>

<style>
    html, body, #app, .kidzonet-app {
        height: 100%;
        color: var(--text-color);
        background-color: var(--app-background-color);
    }

    .dark {
        background-color: var(--app-background-color);
    }

    .dark a {
        color: #fff;
    }

    /* vuetify override */

    .kidzonet-app--ltr {
        .v-field__outline__start {
            border-radius: 8px 0 0 8px !important;
        }
        .v-field__outline__end {
            border-radius: 0 8px 8px 0 !important;
        }
    }

    .kidzonet-app--rtl {
        .v-field__outline__start {
            border-radius: 0 8px 8px 0 !important;

        }
        .v-field__outline__end {
            border-radius: 8px 0 0 8px !important;
        }
    }
</style>
