import { nextTick } from 'vue'
import { createI18n, useI18n } from 'vue-i18n'

import ar from '@kidzonet/locale/ar/errors.json'
import en from '@kidzonet/locale/en/errors.json'
import fr from '@kidzonet/locale/fr/errors.json'

export type LocaleType = 'ar' | 'en' | 'fr'

export const AVALIABLE_LANGUAGES: LocaleType[] = ['ar', 'en', 'fr']

export function setI18nLanguage (i18n: any, locale: LocaleType) {
    i18n.global.locale.value = locale
    document.querySelector('html')?.setAttribute('lang', locale)
}

export const useLoadLocaleMessages = async (file: string) => {
    const { mergeLocaleMessage, locale } = useI18n()
    let translations = []

    let messages
    if (locale.value !== 'en') {
        // @ts-expect-error import translation typisation unnecessary
        translations = await import.meta.glob('../../lib/locale/en/*.json')
        // @ts-expect-error import translation typisation unnecessary
        messages = await translations[`../../lib/locale/en/${file}.json`]()
        mergeLocaleMessage(locale.value, messages.default)
    }

    if (locale.value === 'ar') {
        // @ts-expect-error import translation typisation unnecessary
        translations = await import.meta.glob('../../lib/locale/ar/*.json')
    } else if (locale.value === 'fr') {
        // @ts-expect-error import translation typisation unnecessary
        translations = await import.meta.glob('../../lib/locale/fr/*.json')
    } else {
        // @ts-expect-error import translation typisation unnecessary
        translations = await import.meta.glob('../../lib/locale/en/*.json')
    }
    // @ts-expect-error import translation typisation unnecessary
    messages = await translations[`../../lib/locale/${locale.value}/${file}.json`]()

    mergeLocaleMessage(locale.value, messages.default)

    return nextTick()
}

export const useIsRtl = () => {
    const { locale } = useI18n()
    return locale.value === 'ar'
}

export default () => {
    let initialLanguage: LocaleType = (localStorage.getItem('locale') || navigator.language.substr(2)) as LocaleType

    initialLanguage = AVALIABLE_LANGUAGES.includes(initialLanguage) ? initialLanguage : 'en'

    const i18n = createI18n({
        locale: initialLanguage,
        fallbackLocale: 'en',
        legacy: false,
        missingWarn: false,
        messages: { ar, en, fr },
        globalInjection: true,
    })
    setI18nLanguage(i18n, initialLanguage)
    const isRtl = initialLanguage === 'ar'
    const dir = isRtl ? 'rtl' : 'ltr'
    document.querySelector('html')?.setAttribute('dir', dir)

    // eslint-disable-next-line ts/prefer-ts-expect-error
    // @ts-ignore strange error
    window.KIDZONET_VUE_I18N = i18n.global

    return i18n
}
