// @ts-expect-error not ts
import AuthAPI from '@kidzonet/api-dashboard-auth/src/AuthService'
import RefreshTokenLocalStorageMixin from '@kidzonet/vue3/api/RefreshTokenLocalStorageMixin'
import i18n from '@kidzonet/dashboard-i18n'

import { errorHandler } from '@kidzonet/vue3/api'

const AuthServiceClass = RefreshTokenLocalStorageMixin(AuthAPI)

export const authService = new AuthServiceClass(errorHandler(i18n))
export default authService
