import * as Sentry from '@sentry/vue'
import { clearBrowser } from '@kidzonet/utils/src/logout'
import { notify } from '@kyvg/vue3-notification'
import { ErrorInterface } from './types'

interface I18nInterface {
    global: {
        t: (t: string) => string
    }
}

export const errorHandler = (i18n: I18nInterface) => (e: ErrorInterface) => {
    console.error(e)
    let { status } = e
    if (e?.body?.status) {
        status = e.body.status
    }
    if (status === 401) {
        clearBrowser()
    } else {
        const { t } = i18n.global
        notify({
            type: 'error',
            title: t('errors.error'),
            text: t('errors.server_error'),
        })
        if (
            (e?.error?.message && e?.error?.message?.indexOf?.('network is offline') > -1)
            || (e?.message && e?.message?.indexOf?.('network is offline') > -1)
            || e?.name === 'ChunkLoadError'
            || e?.error?.name === 'ChunkLoadError'
            || e?.body?.detail === 'Token expired'
            || status === 500
        ) {
            return
        }
        if (process.env.NODE_ENV === 'production') {
            Sentry.captureException(e?.body?.message || e)
        }
    }
}
