const MAX_TRIES = 20
const TRY_TIMEOUT = 1000

export default class AbstractAPI {
    constructor (errorHandler) {
        if (this.clearPending) {
            this.clearPending()
        }
        if (!this.handleError && errorHandler) {
            this.handleError = errorHandler
        }
        if (this.afterInitilize) {
            this.afterInitilize()
        }
    }

    checkRefreshTokenPending () {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(this.getRefreshTokenPendingStatus())
            }, TRY_TIMEOUT)
        })
    }

    async waitForRefreshTokenWillBeUpdated (count = MAX_TRIES) {
        if (count > 0) {
            const result = await this.checkRefreshTokenPending()
            if (result) {
                return this.waitForRefreshTokenWillBeUpdated(count - 1)
            }
        }
        return false
    }

    async checkTokenAndCallMethod (method, args, commit, restClientName = 'restClient') {
        try {
            if (this.isTokenExpired() && !this.getRefreshTokenPendingStatus()) {
                await this.startRefreshTokenUpdate()
                const refreshData = await this.refresh(commit)
                if (!refreshData) {
                    return false
                }
            }
            if (this.getRefreshTokenPendingStatus()) {
                await this.waitForRefreshTokenWillBeUpdated()
            }
            this.setRestApi()
            const result = await method.apply(
                Object.assign(this, { restClient: this[restClientName] }), args,
            )
            return result
        } catch (e) {
            this.handleError(e, commit)
        }
        return false
    }

    isTokenExpired () {
        return (new Date().getTime() / 1000) - 60 >= this.getExpiresIn()
    }

    async refresh (commit) {
        try {
            const refreshToken = this.getRefreshToken()
            if (!refreshToken) {
                return false
            }
            const result = await this.refreshApiMethod({
                refresh_token: refreshToken,
            })
            await this.saveRefreshedToken({
                accessToken: result.access_token,
                refreshToken: result.refresh_token,
                expiresIn: new Date().getTime() / 1000 + result.expires_in,
            })
            return true
        } catch (e) {
            this?.clearPending()
            this.handleError(e, commit)
        }
        return false
    }

    async revoke (commit) {
        try {
            await this.revokeApiMethod({ token: this.getRefreshToken() })
            return true
        } catch (e) {
            this.handleError(e, commit)
        }
        return false
    }

    setCommonRestAPI (Client, Service) {
        const restClient = new Client()
        restClient.basePath = this.restApiUrl
        const accessToken = this.getAccessToken()
        if (accessToken) {
            if (restClient?.authentications?.HTTPBearer) {
                // fast api
                restClient.authentications.HTTPBearer.accessToken = accessToken
            } else {
                restClient.authentications = {
                    ApiKeyAuth: {
                        type: 'bearer',
                        accessToken,
                    },
                }
            }
        }
        return new Service(restClient)
    }

    /**
     * (Re)Initialize rest API CLient and set new access token
     */
    setRestApi () {
        const useragent = navigator.userAgent
        if (this.restClientClass && this.restService) {
            this.restClient = this.setCommonRestAPI(
                this.restClientClass, this.restService,
            )
            try {
                this.restClient.apiClient.defaultHeaders['User-Agent'] = useragent
            } catch (e) {
                console.warn('cant set api useragent header')
            }
        }
        this.restAuthClient = this.setCommonRestAPI(
            this.restAuthApiClient, this.restAuthServiceApi,
        )
        try {
            this.restAuthClient.apiClient.defaultHeaders['User-Agent'] = useragent
        } catch (e) {
            console.warn('cant set api useragent header')
        }
    }
}
