import { createRouter, createWebHistory } from 'vue-router'
import PrivacyUrl from '@kidzonet/dashboard-privacy-policy/src/route'
import TOSUrl from '@kidzonet/dashboard-tos/src/route'
import NotFoundUrl from '@kidzonet/vue3/components/errors/404-route'
import SupportUrls from './SupportUrls'
import AuthenticatedUrls from './AuthenticatedUrls'
import LoginUrls from './LoginUrls'

export default createRouter({
    history: createWebHistory('/'),
    routes: [
        ...LoginUrls,
        ...AuthenticatedUrls,
        PrivacyUrl,
        TOSUrl,
        ...SupportUrls,
        NotFoundUrl,
    ],
})
